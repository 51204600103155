@import './varriables';
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin buttons{
    border: 0;
    border-radius: 0px;
    background-color: $primary-color;
    padding: 14px 42px;
    color: #fff;
    text-transform: uppercase;
    margin: 15px 0;
    &:hover{
        background-color: $secondary-color;
    }
}