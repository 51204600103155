@import './_varriables.scss';
@import './_mixins.scss';

/* Reset some default styles */
body,
h1,
p {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-weight: 400;
    color: $text-color;
}

/* Set a base font size and style */
body {
    /* font-family: 'Roboto Mono', monospace; */
    font-family: 'Inconsolata', monospace;
    text-transform: initial;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    // max-width:1920px;
    // margin:0 auto;
    // overflow-x:hidden;
}

a {
    color: $primary-color;
    /* Default link color */
    text-decoration: none;
    /* Remove underlines by default */
    transition: color 0.3s ease;
    /* Smooth transition for color changes */
}

/* Change link color on hover */
a:hover {
    color: $secondary-color;
    /* Color on hover */
}

.hide {
    display: none;
}
.section-title{
    font-weight: 700;
    text-transform: uppercase;
}
/* Optional: Visited link styles */
//   a:visited {
//     color: $accent-color; /* Color for visited links */
//   }
/* Navigation styles */
.social-icons-container{
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    .social-icons{
        display: flex;
        flex-direction: column;
        color: $primary-color;
        font-size: 18px;
        gap: 6px;
        padding-left: 4px;
        // background-color: #fff;
        padding: 8px;
        // writing-mode: vertical-rl;
        a{
            margin: 0;
            padding: 0;
            text-decoration: none;
        }
        
    }
}
.navbar {
    // min-height: 20px;
    background-color: $nav-color;
    padding: 10px;

    .navbar-brand {
        font-size: 30px;
        color: $primary-color;
        font-weight: 700;
    }

    .navbar-nav {
        margin-left: 20px;

        li a {
            text-decoration: none;
            text-transform: uppercase;
            margin-right: 10px;
            font-size: 19px;
        }

        &:active {
            color: $secondary-color;
        }
        &:hover {
            color: $secondary-color;
        }
    }
}

// scroll-indicator
.scroll-indicator {
    position: fixed;
    height: 5px;
    margin-top: 75px;
    width: 100%;
    background-color: #ddd;
    z-index: 1;

    .current-scroll {
        height: 100%;
        background-color: $primary-color;
    }
}


// General Section style

// *** Styling slider css
.slider {
    min-height: 100vh;
    // margin-top: 60px;
    padding-top: 60px;
    background-color: $accent-color;
    @include flex-center();
    flex-direction: column;

    .slider-content {
        // padding-left: 30px;

        .hello {
            text-transform: uppercase;
            font-size: 22px;
        }

        h1 {
            font-size: 80px;
            font-weight: 700;
        }

        h2 {
            font-size: 28px;
            color: $secondary-color;
        }

        p {
            font-size: 16px;
            line-height: 28px;
            width: 70%;

        }

        button {
            @include buttons;
        }
        .slider-social-icons-container{
            display: none;
        }
    }
}

// Styling about me section
.about {
    min-height: 100vh;
    // display: flex;
    gap: 10px;
    @include flex-center();

    .my-photo {
        width: 60%;
        @include flex-center();

        img {
            max-width: 60%;
        }
    }

    .about-me {
        width: 50%;

        p {
            width: 70%;
            font-size: 16px;
        }

        button {
            @include buttons();
        }
    }
}

// Styling the work experience section
.work-experience {
    min-height: 100vh;
    @include flex-center();
    flex-direction: column;

    h2 {
        margin: 15px 0;
    }

    .works {
        .card {
            margin: 25px 0;
            width: 800px;

            .card-body {
                padding: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h5 {
                    font-size: 24px;
                    margin-bottom: 15px;
                }

                h6 {
                    font-style: italic;
                }

                ul li {
                    color: #333;
                    // width: 80%;
                    margin: 5px 0;
                }

                a {
                    color: $primary-color;
                    font-weight: 800;
                }
            }
        }
    }
}

// Styling the project section
.projects {
    min-height: 100vh;
    padding: 6% 0;
    @include flex-center;
    flex-direction: column;

    .project-tabs {
        .project-tab {
            display: flex;
            gap: 5px;

            // border-bottom: 5px solid $primary-color;
            p {
                cursor: pointer;
                text-transform: uppercase;
                padding: 10px 15px;
                text-align: center;
                background-color: $accent-color;
                border-radius: 10px;

                &.active {
                    background-color: $primary-color;
                    color: #fff;
                }

                &.hover {
                    background-color: $primary-color;
                    color: #fff;
                }
            }
        }
    }

    .projects-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        transition: all 3s;
        margin: 30px auto;
        width: 90%;

        .project-card {
            min-width: 350px;
            flex: 0 1 calc(33.33% - 20px);
            min-height: 550px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px;
            // background-color: #fefefe;
            box-sizing: border-box;
            box-shadow: rgba(55, 196, 185, 0.2) 0px 7px 29px 0px;
            .project-item {
                // display: flex;
                padding: 40px; 
                .project-name {
                    h2 {
                        font-size: 22px;
                        // text-transform: capitalize;
                        color: $primary-color;
                        font-weight: 600;
                    }
                }

                .project-details {
                    padding: 20px;
                    background-color: #fff;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    margin: 15px 0;
                }

                .project-tools {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                    margin-bottom: 15px;

                    p {
                        // color: #fff;
                        background-color: $accent-color;
                        padding: 5px 10px;
                        font-size: 16px;
                    }
                }


            }

        }

        button {
            @include buttons;
        }
    }
}

// Styling the activity section
.activity {
    @include flex-center();
    flex-direction: column;
    min-height: 50vh;
    padding: 50px 0;

    .activities-card-wrapper {

        // background-color: #fefefe;
        .activities-card {
            min-width: 800px;
            height: 300px;
            // background-color: rgba(55, 196, 185, 0.2);
            padding: 10px 80px;
            margin: 10px 10px;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // border-bottom: 3px solid $primary-color;
            // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            box-shadow: rgba(55, 196, 185, 0.4) 0px 7px 29px 0px;

            .arrow {
                position: absolute;
                color: #555;
                cursor: pointer;
            }

            .arrow-left {
                left: 7%;
            }

            .arrow-right {
                right: 7%;
            }

            .activity {
                .activity-name {
                    font-size: 20px;

                }

                .activity-date {
                    font-style: italic;
                }

                .activity-details {
                    width: 80%;
                    font-size: 18px;
                    text-align: center;
                }
            }

            .dot-indicators {
                position: absolute;
                bottom: 1rem;
                cursor: pointer;
                display: flex;
                gap: 5px;
                color: $primary-color;

                .dot-indicator {
                    flex-direction: row !important;

                }
            }

        }
    }

}

// Styling the Contact section
.contact {
    min-height: 70vh;
    background-color: $accent-color;
    @include flex-center();
    flex-direction: column;

    .contact-card {
        @include flex-center();
        flex-direction: column;
        width: 500px;
        min-height: 250px;
        padding: 50px 100px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: #fff;
        transition: all 0.5s;
        cursor: pointer;
        border-radius: 30px;

        &:hover {
            background-color: $primary-color;
            color: #fff !important;

            a {
                color: #fff;
            }
        }

        h2 {
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 15px;
            font-size: 25px;
        }

        .contact-icons {
            @include flex-center();
            gap: 2em;

            a {
                font-size: 30px;
            }
        }

    }

    .contact-details {
        display: flex;
        gap: 1em;
        align-items: center;
        margin: 10px 0;
    }
}

footer {
    background-color: $primary-color;
    text-align: center;
    min-height: 10vh;
    @include flex-center();

    p {
        color: #fff !important;
    }
}