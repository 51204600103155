$primary-color: #003135;
$secondary-color: #024950;
$accent-color: #ebf6f5;
$nav-color: #eee;
$text-color: #333;

[data-theme="dark"] {
    $primary-color: #37C4B9;
    $secondary-color: #0A3E3F;
    $accent-color: #0A3E3F;
    $nav-color: #0A3E3F;
}